/*////////////////////////////////////////
//  Title: Base
//  Summary: Default styles
//	Author: Josh McGrath
////////////////////////////////////////*/

*
	box-sizing: border-box;

html
  font-family font1
  font-size base-font-size
  line-height _baseline

	position relative
	background-color white

body
	position relative
	background-color white

/*///////////////////////*/
/*//// Text Elements ////*/
/*///////////////////////*/

h1,h2,h3
	font-bold()
	margin 0

p
	font-normal()

/*////////////////*/
/*//// Images ////*/
/*////////////////*/

img
	max-width 100%

/*/////////////////////////*/
/*//// Buttons & Links ////*/
/*/////////////////////////*/

button
	outline none
	border none
	padding 0

a
	-webkit-tap-highlight-color rgba(0,0,0,0)
	color _red
	text-decoration none
	cursor pointer
	transition color speed function

	&:hover
		color lighten(_red,20%)

/*///////////////////*/
/*//// Utilities ////*/
/*///////////////////*/

.hide
	display none

.underline
	text-decoration underline

.bold,b
	font-bold()
