/*////////////////////////////////////////
//	Title: Font
//	Summary: Mixins for setting fonts.
//	Author: Josh McGrath
////////////////////////////////////////*/

// Good for TypeKit fonts, or other fonts that don’t break out weights as separate fonts.

// font-normal([fontName:String])
// [fontName:String] : Name of the font, or font variable. Defaults to font-primary.

font-normal(fontName = font-primary)
	font-family: fontName;
	font-weight: 400;
	font-style: normal;

// font-bold([fontName:String])
// [fontName:String] : Name of the font, or font variable. Defaults to font-primary.

font-bold(fontName = font-primary)
	font-family: fontName;
	font-weight: 700;
	font-style: normal;

font-extra-bold(fontName = font-primary)
	font-family: fontName;
	font-weight: 800;
	font-style: normal;

font-heavy(font-name = font-primary)
	font-extra-bold(fontName)

font-black(fontName = font-primary)
	font-family: fontName;
	font-weight: 900;
	font-style: normal;

// font-italic([fontName:String])
// [fontName:String] : Name of the font, or font variable. Defaults to font-primary.

font-italic(fontName = font-primary)
	font-family: fontName;
	font-weight: 400;
	font-style: italic;
