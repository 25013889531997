html,body
  background-color: #06040F;

body > .ember-view
  position: relative;

.container-placeholder
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width 100%
  text-align: center;
  z-index: 2;

canvas
  display: block;
  position: relative;
  z-index: 1;
  // width 100%
  // height 100%

.logo,.byline
  color white
  text-align: center;

.logo
  font-black(font-logo)
  font-size: 10rem;
  line-height: 1;
  // color rgba(white,0.25)
  // color #06040F
  pointer-events: none;
  margin:0;

  +above(screen-sm)
    font-size: 20rem;

.byline
  font-bold(font-copy)
  font-size: 1.5rem;
  line-height: 1;
  margin 0

  +above(screen-sm)
    font-size: 2rem;

.copy
  font-normal(font-copy)
  font-size: 1rem;
  line-height: 2;
  color white
  background-color: #06040F;
  padding:1rem;
  border-radius: 0.25rem;
  text-align: center;
  display: inline-block;

  +above(screen-sm)
    font-size: 1.5rem;
    padding: 0 2rem;
    border-radius: 40rem;
