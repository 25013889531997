/*////////////////////////////////////////
//  Title: Lark
//  Summary: A vertical rhythm system.
//	Author: Josh McGrath
////////////////////////////////////////*/

/*///////////////////////////////*/
/*//// Set a Vertical Rhythm ////*/
/*///////////////////////////////*/

// rhythm(scaleIndex:Int, [rows:Int], [ems:Bool], [modularScale:Float])
// For setting web type to a baseline grid
// scaleIndex:Int 		: Number of scale units you want the font-size to be.
// [rows:Int] 			: Height of the text element in baseline units. Defaults to null.
// [ems:Bool] 			: Change the unit of measurement to ems. Defaults to false.
// [modularScale:Float] : The modular scale you want to use. Defaults to global variable.

_rhythm(scaleIndex,rows = null,ems = false,modularScale = _ratio)
	//1) Get the modular scale font size
	fontSize = _scale(scaleIndex, modularScale)
	//2) Get our unit of measurement
	if ems == true
		u = 'em'
	else
		u = 'rem'

	//3) Set the font-size
	font-size unit(fontSize,u)

	//4) Check if we are setting the number of baseline
	if rows != null
		line-height (_baseline / fontSize) * rows
	else
		//4b 1) If not, find out how many rows minimum we need to contain the text
		baselineRows = ceil(fontSize/_baseline)
		//4b 2) Set the line-height such that the text height is a multiple of the baseline
		line-height (_baseline / fontSize) * baselineRows

/*//////////////////////*/
/*//// Adding Space ////*/
/*//////////////////////*/

// lead(rows:Int,[ems:Bool],[scaleIndex:Int],[modularScale:Float])
// Returns a multiple of the global baseline unit.
// rows:Int 			Number of baseline grid rows to lead
// [ems:Bool] 			Set the unit of measurement to ems. Defaults to false.
// [scaleIndex:Int] 	If using ems, specify the context size. Defaults to 0.
// [modularScale:Float] The modular scale you want to use. Defaults to global variable.

_lead(rows, ems = false, scaleIndex = 0, modularScale = _ratio)
	if ems == true
		fontSize = _scale(scaleIndex,modularScale)
		unit(_baseline * rows / fontSize,'em')
	else
		unit(_baseline * rows,'rem')

/*///////////////////////*/
/*//// Get Font Size ////*/
/*///////////////////////*/

// scale(scaleIndex:Int,[modularScale:Float])
// Returns a font size (float) based on a modular scale.
// scaleIndex:Int		: Number of scale units you want the font-size to be.
// [modularScale:Float] : The modular scale you want to use. Defaults to global variable.

_scale(scaleIndex,modularScale = _ratio,withUnits=false)
	if withUnits
		unit((modularScale ** scaleIndex),'rem')
	else
		(modularScale ** scaleIndex)

/*////////////////////*/
/*//// Debug Grid ////*/
/*////////////////////*/

// Styles for the debug grid generated using javascript.
// It is set using REMs and responds to changes to the html element’s font-size

.grid-debug
	font-size inherit
	position absolute
	width auto
	height auto
	z-index 9999
	content ''
	display block
	pointer-events none
	top 0
	right 0
	bottom 0
	left 0
	overflow hidden

	.section-grid
		height _lead(1,true)
		&:nth-child(odd)
			background-color hsla(black,12%)

html:active .grid-debug
	display none
